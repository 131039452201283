import * as React from "react"
import { graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col } from "react-bootstrap"


// import { getImage } from "gatsby-plugin-image"
// import { BgImage } from 'gbimage-bridge';

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Testimonials" />
    <h1>Testimonials</h1>
    <Container>
      <Row>
        <Col lg={6}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>Dear Thomas, I want to tell you about the encounter I had with the local electrical inspector … The inspector said “Thomas is the best electrician in the county”, so it is with great authority that I highly recommend Thomas Tripolt to be your electrician as he has been mine for the past 26 years.</p>
                </blockquote>  
                <p className="text-center">
                Anna Halprin, Kentfield, CA
                </p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>I have been fortunate enough to have had Thomas of All Electric on multiple home projects for me. In each instance, he had done superb work, always been on time and left a cleaner work site than when he arrived. I cannot recommend him more highly!</p>
                </blockquote>  
                <p className="text-center">
                Madelyn Kahn, Kentfield, CA
                </p>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>I have known Thomas Tripolt for 28 years. He has done many electrical jobs for me, both residential and commercial. He is experienced for all kinds of jobs and is willing to do everything from the smallest home repairs and installations to bidding and doing large projects. Thomas will explain your options. He is Old World courteous and efficient (Austrian) and when the job is finished you know you have gotten value received. His prices are lower than most because he operates without an expensive shop and he relies on recommendations rather than large advertisements. Please call me if you would like to discuss Thomas’s qualifications or background in person.</p>
                </blockquote>  
                <p className="text-center">
                Jeffory Morshead, Kentfield, CA
                </p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>For twelve years, Thomas Tripolt has kept “the lights on” at our home in Mill Valley with his exceptional combination of Old World excellence and integrity. He has replaced ancient wiring, installed new technology electric panels and new lighting fixtures and wired us for outdoor lighting. He is always on time and within budget. And, no small matter when someone works in your home, he’s an easy presence.</p>
                </blockquote>  
                <p className="text-center">
                Pete Abrahams & Connie Dubin, Mill Valley, CA
                </p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>I give 5 stars, top rating to Thomas Tripolt for his fine work on all electrical systems. Thomas has worked on jobs for me for over 20 years, both residential and commercial. I have always been totally satisfied with the results. Thomas sees the issue, works calmly, cleanly and resolves the issue thoroughly and the job is done. Thank you Thomas for all the good work you have done for me over the years.</p>
                </blockquote>  
                <p className="text-center">
                Elaine Horschman, Larkspur and Corte Madera
                </p>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>As a Contractor who develops the built home, I’ve been working on several projects where clients have chosen All Electric and were satisfied with work, manner, pricing and results. Thomas, the owner of All Electric has a good crew that can work on new residential and remodeling projects as well as commercial ones. Thomas does not over-promise, is reliable, honest, competent and competitively priced. His projects are on schedule and within budget which many contractors claim but few deliver.</p>
                </blockquote>  
                <p className="text-center">
                Gay Cohen, Golden Restoration and Construction Inc., Novato, CA
                </p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>All Electric is by far the most capable, reliable and trustworthy electrician we have ever used. We have used Thomas for a variety of projects in our large home and we have always been happy with the work. Thomas is a skilled electrician with a lot of experience as well as one who is comfortable telling you if something cannot be done.</p>
                </blockquote>  
                <p className="text-center">
                Liz Hale, Kentfield, CA
                </p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>Thomas has been our on-call electrician for the past seven years. He is a great person to work with, as he is always available to handle our electrical needs. Believe me, we really had to utilize his talents! He is thorough, dependable and he is a pleasure to work with. I recommend his services to everyone!</p>
                </blockquote>  
                <p className="text-center">
                Lydia Kindheart, Lydia's Organics, Petaluma
                </p>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="testimonial-quote group">
            <div className="quote-container">
                <blockquote>
                    <p>Thomas has been our primary electrician for 10 years, having wired our home and two businesses. He is always prompt and efficient, knowledgeable on how to solve problems in creative ways or introduce new products to implement reliability. Thomas is the best in business!</p>
                </blockquote>  
                <p className="text-center">
                F. McCartney, Marin County, CA
                </p>
            </div>
          </div>
        </Col>
      </Row>
      
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query { 
    light: file(relativePath: {eq: "background-light.jpg"}) {
      relativePath
      base
      childImageSharp {
        gatsbyImageData(
          webpOptions: {quality: 50}
          blurredOptions: {width: 50, toFormat: WEBP}
          width: 2000
          formats: AUTO
          jpgOptions: {progressive: false, quality: 50}
        )
      }
    }
  }
`